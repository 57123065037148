import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import {
  Alert,
  Box,
  Avatar,
  TextField,
  Grid,
  makeStyles,
  PagePreloader,
  SimpleList,
  SwitchButton,
  ToggleButton,
  ToggleButtonGroup,
  DateRangePicker,
  P,
  Chip,
  H3,
  useDarkMode,
  Icon,
} from '@sport-thieme/react-ui'
import { localize } from '@sport-thieme/util-localization'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/ace'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/mode-graphqlschema'
import 'ace-builds/src-noconflict/theme-chrome'
import 'ace-builds/src-noconflict/theme-tomorrow_night'
import 'ace-builds/src-noconflict/ext-searchbox'

import { useLazyQuery } from '@apollo/client'
import listLogMutationsQuery, {
  DateRange,
  GraphqlMutationLog,
  isGraphQLMutationLog,
  ListLogMutationsQueryInput,
  ListLogMutationsQueryResult,
  MutationLogFilterInput,
  OriginEnum,
  RestMutationLog,
} from '../graphql/listLogMutationsQuery'

import CryptoJS from 'crypto-js'
import moment from 'moment'
import RequestView from './RequestView'
import { Tooltip } from '@material-ui/core'

const MAX_ITEMS = 100

export default function RequestList(): ReactElement {
  const [filter, setFilter] = useState<MutationLogFilterInput>({})
  const [poll, setPoll] = useState(true)
  const [openEntry, setOpenEntry] = useState<RestMutationLog | GraphqlMutationLog | undefined>(
    undefined,
  )
  const [darkModeActive] = useDarkMode()
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)
  const [type, setType] = useState<OriginEnum>(OriginEnum.rest)
  const classes = useStyles()

  const [query, { data, loading, error, fetchMore }] = useLazyQuery<
    ListLogMutationsQueryResult,
    ListLogMutationsQueryInput
  >(listLogMutationsQuery, {
    pollInterval: poll ? 30000 : undefined,
  })

  const dateRangePickerValue = useMemo(() => {
    if (filter.range === undefined) {
      return undefined
    }

    const start = new Date(filter.range.start)
    const end = new Date(filter.range.end)

    end.setDate(end.getDate() - 1)

    return { start, end }
  }, [filter.range])

  useEffect(() => {
    const timeout = setTimeout(
      () => query({ variables: { origin: type, filter: filter, take: MAX_ITEMS, skip: 0 } }),
      1000,
    )
    return () => clearTimeout(timeout)
  }, [filter, query, type])

  const handleScroll = async (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (
      event.currentTarget.scrollHeight ===
        event.currentTarget.scrollTop + event.currentTarget.offsetHeight &&
      (data?.listMutationLogs.logs.length ?? 0) < (data?.listMutationLogs.count ?? 0) &&
      loading === false &&
      fetchMoreLoading === false
    ) {
      setFetchMoreLoading(true)
      event.currentTarget.scrollTop = event.currentTarget.scrollHeight
      fetchMore?.({
        variables: {
          origin: type,
          filter: filter,
          take: MAX_ITEMS,
          skip: data?.listMutationLogs.logs.length ?? 0,
        },
        updateQuery: (prev, next) => {
          const items = [
            ...prev.listMutationLogs.logs,
            ...(next.fetchMoreResult?.listMutationLogs.logs || []),
          ]

          setFetchMoreLoading(false)
          return {
            listMutationLogs: {
              count: prev.listMutationLogs.count,
              logs: items,
            },
          }
        },
      })
    }
  }

  return (
    <div className={classes.workspace}>
      <div className={classes.filters}>
        <Box padding={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item grow>
              <TextField
                label={localize`operation name`}
                value={filter.operation ?? ''}
                onChange={(event) =>
                  setFilter({
                    ...filter,
                    operation: Boolean(event.target.value.trim()) ? event.target.value : undefined,
                  })
                }
              />
            </Grid>
            <Grid item grow>
              <TextField
                label={localize`email`}
                value={filter.email ?? ''}
                onChange={(event) =>
                  setFilter({
                    ...filter,
                    email: Boolean(event.target.value.trim()) ? event.target.value : undefined,
                  })
                }
              />
            </Grid>
            <Grid item>
              <DateRangePicker
                value={dateRangePickerValue}
                clearable
                onChange={(range) => {
                  let newRange: DateRange | undefined = undefined
                  if (range !== undefined) {
                    const endDate = new Date(range.end)
                    endDate.setDate(endDate.getDate() + 1)
                    newRange = {
                      start: range.start.toISOString(),
                      end: endDate.toISOString(),
                    }
                  }

                  setFilter({
                    ...filter,
                    range: newRange,
                  })
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <AceEditor
          className={classes.ace}
          height="auto"
          mode="json"
          fontSize={16}
          theme={darkModeActive ? 'tomorrow_night' : 'chrome'}
          width="100%"
          value={filter.json}
          onChange={(newValue) =>
            setFilter({ ...filter, json: newValue !== '' ? newValue : undefined })
          }
        />
        <div className={classes.easyFilter}>
          <Box padding={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <ToggleButtonGroup
                  value={type}
                  exclusive
                  onChange={(_, newType) => {
                    setType(newType)
                  }}
                >
                  <ToggleButton value={OriginEnum.graphql}>{localize`GQL`}</ToggleButton>
                  <ToggleButton value={OriginEnum.rest}>{localize`REST`}</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item>
                <SwitchButton
                  label={localize`Poll`}
                  checked={poll}
                  onChange={(_, checked) => setPoll(checked)}
                />
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
      <div className={classes.listWrapper} onScroll={handleScroll}>
        {error && (
          <Grid container className={classes.fullSizeWrapper} justify="center" alignItems="center">
            <Alert severity="error">{error.message}</Alert>
          </Grid>
        )}
        {loading && <PagePreloader fillContainer />}
        {loading === false && (
          <SimpleList
            data={data?.listMutationLogs.logs.map((entry) => {
              const { email, operation, date } = entry
              const dateFormatted =
                moment(date) < moment().subtract(2, 'days')
                  ? moment(date).format('LLL')
                  : moment(date).fromNow()

              return {
                primary: operation || localize`operation name not given`,
                secondary: `${email || localize`No email`} • ${dateFormatted}`,
                onClick: () => setOpenEntry(entry),
                trailingIcon:
                  isGraphQLMutationLog(entry) && entry.isProduction === false ? (
                    <Tooltip title={<P>{localize`This mutation was running on staging`}</P>}>
                      <Icon.Build />
                    </Tooltip>
                  ) : (
                    ''
                  ),
                icon: (
                  <Avatar src={`https://pim.sport-thieme.de/userimage/${CryptoJS.MD5(email)}`} />
                ),
              }
            })}
          />
        )}
        {fetchMoreLoading && (
          <Box padding={2}>
            <Grid container justify="center">
              <Grid item>
                <P>{localize`Brace yourselfs, more items loading…`}</P>
              </Grid>
            </Grid>
          </Box>
        )}
        {(data?.listMutationLogs.count ?? 0) > 0 && (
          <Chip
            className={classes.found}
            label={localize`Found items: ${String(data?.listMutationLogs.count)}`}
          />
        )}
        {Boolean(error) === false &&
          loading === false &&
          (data?.listMutationLogs.count ?? 0) === 0 && (
            <Grid
              container
              className={classes.fullSizeWrapper}
              justify="center"
              alignItems="center"
            >
              <H3>{localize`No data found`}</H3>
            </Grid>
          )}
      </div>
      <RequestView entry={openEntry} onClose={() => setOpenEntry(undefined)} />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  ace: {
    flexGrow: 1,
  },
  fullSizeWrapper: {
    minHeight: '100%',
  },
  found: {
    position: 'fixed',
    bottom: 30,
    right: 30,
  },
  easyFilter: {},
  filters: {
    display: 'flex',
    flexDirection: 'column',
  },
  listWrapper: {
    boxShadow: '0 0 30px -10px #000',
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
    },
  },
  workspace: {
    height: `calc(100vh - 56px)`,
    display: 'grid',
    gridTemplateColumns: ` 2fr 3fr`,
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'auto',
      gridTemplateRows: '1fr 5fr',
    },
    '&>*': {
      maxHeight: '100%',
      overflow: 'auto',
    },
  },
}))
