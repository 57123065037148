import { gql } from '@sport-thieme/react-authenticator'

export default gql`
  {
    currentUser {
      firstname
      image
    }
  }
`
