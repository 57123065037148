import { gql } from '@sport-thieme/react-authenticator'
export default gql`
  query requestLogAppListLogs(
    $skip: Int
    $take: Int
    $origin: MutationLogOriginEnum
    $filter: MutationLogFilterInput
  ) {
    listMutationLogs(skip: $skip, take: $take, origin: $origin, filter: $filter) {
      count
      logs {
        date
        email
        operation
        ip
        ... on RestMutationLog {
          request
          shopId
          languageId
          response
          userId
        }
        ... on GraphqlMutationLog {
          isProduction
          query
          variables
          referrer
        }
      }
    }
  }
`
export type ListLogMutationsQueryResult = {
  listMutationLogs: {
    count: number
    logs: Array<RestMutationLog | GraphqlMutationLog>
  }
}

export enum OriginEnum {
  graphql = 'graphql',
  rest = 'rest',
}

export type ListLogMutationsQueryInput = {
  skip?: number
  take?: number
  origin?: OriginEnum
  filter?: MutationLogFilterInput
}

export type MutationLogFilterInput = {
  json?: string
  email?: string
  operation?: string
  ip?: string
  referrer?: string
  range?: DateRange
}

export type DateRange = {
  start: string
  end: string
}

export interface MutationLog {
  date: string
  email: string
  ip: string
  operation: string
}

export interface RestMutationLog extends MutationLog {
  request: string
  shopId: number
  languageId: number
  userId: number
  response: string
}

export interface GraphqlMutationLog extends MutationLog {
  isProduction: boolean
  query: string
  variables: string
  referrer: string
}

export function isGraphQLMutationLog(
  log: GraphqlMutationLog | RestMutationLog,
): log is GraphqlMutationLog {
  return 'query' in log
}

export function isRestMutationLog(
  log: GraphqlMutationLog | RestMutationLog,
): log is RestMutationLog {
  return isGraphQLMutationLog(log) === false
}
