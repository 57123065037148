/**
 * Add any global styles which you get from the designer in this document
 * https://material-ui.com/customization/default-theme/
 */
export const theme = {
  palette: {
    primary: {
      main: '#73961d',
    },
    secondary: {
      main: '#eb9008',
    },
  },
}
