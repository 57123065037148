import React, { useMemo } from 'react'
import {
  Avatar,
  Divider,
  DrawerOverlay,
  Grid,
  H5,
  H6,
  Clipboard,
  Icon,
  IconButton,
  P,
  useDarkMode,
  Chip,
  Tooltip,
} from '@sport-thieme/react-ui'
import { localize } from '@sport-thieme/util-localization'
import CryptoJS from 'crypto-js'
import moment from 'moment'

import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/mode-graphqlschema'

import {
  GraphqlMutationLog,
  isGraphQLMutationLog,
  isRestMutationLog,
  RestMutationLog,
} from '../graphql/listLogMutationsQuery'

export default function RequestView({ entry, onClose }: RequestViewProps): JSX.Element {
  const [darkModeActive] = useDarkMode()
  const { email, date, operation } = entry || {}

  const dateFormatted =
    moment(date) < moment().subtract(2, 'days')
      ? moment(date).format('LLLL')
      : moment(date).fromNow()

  const rawFieldValue = useMemo(() => {
    if (entry !== undefined && isRestMutationLog(entry)) {
      try {
        return JSON.parse(entry.request)['fieldValue'] as string
      } catch {
        return 'null'
      }
    }
  }, [entry])

  return (
    <DrawerOverlay
      disableEnforceFocus
      open={Boolean(entry)}
      onClose={onClose}
      header={
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Avatar src={`https://pim.sport-thieme.de/userimage/${CryptoJS.MD5(email ?? '')}`} />
          </Grid>
          <Grid item grow>
            <H6>{operation || localize`operation name not given`}</H6>
            <P>{`${email || localize`No email`} • ${dateFormatted}`}</P>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <Icon.Close />
            </IconButton>
          </Grid>
        </Grid>
      }
    >
      {entry !== undefined && isGraphQLMutationLog(entry) && (
        <>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item grow>
              <H5 gutterBottom>{localize`Graphql mutation`}</H5>
            </Grid>
            <Grid item>
              <Clipboard text={entry.query}>
                <Tooltip title={localize`Copy the mutation`}>
                  <IconButton>
                    <Icon.FileCopy />
                  </IconButton>
                </Tooltip>
              </Clipboard>
            </Grid>
          </Grid>
          <AceEditor
            height={`${entry.query.split('\n').length * 22}px`}
            mode="graphqlschema"
            theme={darkModeActive ? 'tomorrow_night' : 'chrome'}
            fontSize={16}
            width="100%"
            value={entry.query}
            readOnly
          />
          <Divider withMargin />
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item grow>
              <H5 gutterBottom>{localize`Variables`}</H5>
            </Grid>
            <Grid item>
              <Clipboard text={entry.variables}>
                <Tooltip title={localize`Copy the variables`}>
                  <IconButton>
                    <Icon.FileCopy />
                  </IconButton>
                </Tooltip>
              </Clipboard>
            </Grid>
          </Grid>
          <AceEditor
            height={`${entry.variables.split('\n').length * 22}px`}
            mode="json"
            theme={darkModeActive ? 'tomorrow_night' : 'chrome'}
            fontSize={16}
            width="100%"
            readOnly
            value={entry.variables}
          />
          <Divider withMargin />
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <H5 gutterBottom>{localize`Referrer`}</H5>
            </Grid>
            <Grid item>
              <Chip label={entry.referrer ?? localize`Unknown`} />
            </Grid>
          </Grid>
          <Divider withMargin />
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <H5 gutterBottom>{localize`Stage`}</H5>
            </Grid>
            <Grid item>
              <Chip label={entry.isProduction ? localize`Production` : localize`Development`} />
            </Grid>
          </Grid>
        </>
      )}

      {entry !== undefined && isRestMutationLog(entry) && (
        <>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item grow>
              <H5 gutterBottom>{localize`Request`}</H5>
            </Grid>
            <Grid item>
              <Clipboard text={rawFieldValue}>
                <Tooltip title={localize`Copy raw field value`}>
                  <IconButton>
                    <Icon.FileCopyOutlined />
                  </IconButton>
                </Tooltip>
              </Clipboard>
            </Grid>
            <Grid item>
              <Clipboard text={entry.request}>
                <Tooltip title={localize`Copy the whole request`}>
                  <IconButton>
                    <Icon.FileCopy />
                  </IconButton>
                </Tooltip>
              </Clipboard>
            </Grid>
          </Grid>
          <AceEditor
            height={`${entry.request.split('\n').length * 22}px`}
            mode="json"
            theme={darkModeActive ? 'tomorrow_night' : 'chrome'}
            fontSize={16}
            width="100%"
            value={entry.request}
            readOnly
          />
          <Divider withMargin />
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item grow>
              <H5 gutterBottom>{localize`Response`}</H5>
            </Grid>
            <Grid item>
              <Clipboard text={entry.response}>
                <Tooltip title={localize`Copy the whole response`}>
                  <IconButton>
                    <Icon.FileCopy />
                  </IconButton>
                </Tooltip>
              </Clipboard>
            </Grid>
          </Grid>
          <AceEditor
            height={`${entry.response.split('\n').length * 22}px`}
            mode="json"
            theme={darkModeActive ? 'tomorrow_night' : 'chrome'}
            fontSize={16}
            width="100%"
            value={entry.response}
            readOnly
          />
          <Divider withMargin />
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <H5 gutterBottom>{localize`Context`}</H5>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Chip label={localize`Shop id: ${String(entry.shopId)}`} />
                </Grid>
                <Grid item>
                  <Chip label={localize`Language id: ${String(entry.languageId)}`} />
                </Grid>
                <Grid item>
                  <Chip label={localize`User id: ${String(entry.userId)}`} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      <Divider withMargin />
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <H5 gutterBottom>{localize`Ip`}</H5>
        </Grid>
        <Grid item>
          <Chip label={entry?.ip} />
        </Grid>
      </Grid>
    </DrawerOverlay>
  )
}

export type RequestViewProps = {
  entry: GraphqlMutationLog | RestMutationLog | undefined
  onClose: () => void
}
