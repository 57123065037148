import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import LogCollector from '@sport-thieme/react-log-collector'
import Authenticator, { GraphProvider } from '@sport-thieme/react-authenticator'
import { ThemeProvider } from '@sport-thieme/react-ui'
import * as serviceWorker from './serviceWorker'
import { theme } from './Theme'

ReactDOM.render(
  <React.StrictMode>
    <LogCollector>
      <Authenticator>
        <ThemeProvider theme={theme}>
          <GraphProvider>
            <App />
          </GraphProvider>
        </ThemeProvider>
      </Authenticator>
    </LogCollector>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
