import React, { ReactElement, useContext } from 'react'
import { SimpleAppBar, CssBaseline } from '@sport-thieme/react-ui'
import { AuthenticatorContext, useQuery } from '@sport-thieme/react-authenticator'
import currentUser from './graphql/currentUser'
import Localization, { localize } from '@sport-thieme/util-localization'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import de from './resources/de.json'
import RequestList from './components/RequestList'

Localization.addTranslations('de', de)

export default function App(): ReactElement {
  const { data } = useQuery<{
    currentUser?: { firstname?: string; image?: string }
  }>(currentUser)
  const { signOut } = useContext(AuthenticatorContext)

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{localize`Mutation logs - Sport-Thieme`}</title>
      </Helmet>
      <Router>
        <SimpleAppBar
          signOut={signOut}
          logoImage={'https://pimage.sport-thieme.de/icon64/app-icon-log'}
          title={localize`mutation logs`}
          username={data?.currentUser?.firstname}
          avatarImage={data?.currentUser?.image}
        />
        <CssBaseline />
        <Route exact path={'/'}>
          <RequestList />
        </Route>
      </Router>
    </HelmetProvider>
  )
}
